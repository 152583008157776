export default function copyFormatted(html: string) {
  const container = document.createElement('div');
  container.innerHTML = html;

  // Append the container to the DOM to make it selectable
  document.body.appendChild(container);

  const range = document.createRange();
  range.selectNode(container);

  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);

  try {
    document.execCommand('copy');
    console.log('HTML content copied to clipboard successfully.');
  } catch (err) {
    console.error('Failed to copy HTML content to clipboard: ', err);
  }

  // Clean up: Remove the container from the DOM
  document.body.removeChild(container);
}
