import React from 'react';
import '../styles/components/Nav.scss';

const Nav = () => {
  return (
    <nav className='navbar'>
      <div className='logo'>
        <img src='/img/logo.png' alt='Austin Werner logo' />
      </div>
    </nav>
  );
};

export default Nav;
