import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Generated from './components/Generated';

const GeneratedPage = () => {
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const name = query.get('name');
  const occupation = query.get('occupation');
  const phone = query.get('phone');
  const alternatePhone = query.get('alternatePhone');
  const email = query.get('email');
  const hasBanner = query.get('hasBanner') || '';

  useEffect(() => {
    if (!name || !occupation || !phone || !email) {
      navigate('/');
    }
  }, [navigate, email, name, phone, occupation]);

  return (
    <Generated
      name={name as string}
      email={email as string}
      occupation={occupation as string}
      phone={phone as string}
      alternatePhone={alternatePhone as string}
      hasBanner={hasBanner === 'true'}
    />
  );
};

export default GeneratedPage;
