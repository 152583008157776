import React, { useState } from 'react';
import '../styles/components/Form.scss';

const Form = ({ onGenerateClick, onCopyToClipboardClick }: FormProps) => {
  const [name, setName] = useState('');
  const [occupation, setOccupation] = useState('');
  const [phone, setPhone] = useState('');
  const [alternatePhone, setAlternatePhone] = useState('');
  const [email, setEmail] = useState('');
  const [hasBanner, setHasBanner] = useState(true);

  const isButtonDisabled = (): boolean => {
    return name === '' || occupation === '' || phone === '' || email === '';
  };

  const buttonDisabled = isButtonDisabled();

  return (
    <div className='form'>
      <label htmlFor='name'>Name</label>
      <input
        id='name'
        placeholder='John smith'
        type='text'
        value={name}
        onChange={(e) => setName(e.target.value)}
        required={true}
      />

      <label htmlFor='occupation'>Occupation</label>
      <input
        id='occupation'
        placeholder='Director / Founder'
        type='text'
        value={occupation}
        onChange={(e) => setOccupation(e.target.value)}
        required={true}
      />

      <label htmlFor='phone'>Phone</label>
      <input
        id='phone'
        placeholder='+420 606 456 789'
        type='tel'
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required={true}
      />
      <label htmlFor='alternatePhone'>Alternate Phone (Optional)</label>
      <input
        id='alternatePhone'
        placeholder='+420 606 456 789'
        type='tel'
        value={alternatePhone}
        onChange={(e) => setAlternatePhone(e.target.value)}
        required={true}
      />

      <label htmlFor='email'>Email</label>
      <input
        id='email'
        placeholder='info@altron.cz'
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
      />
      <div className='checkbox'>
        <label htmlFor='hasBanner'>Include Banner (Optional)</label>
        <input
          id='hasBanner'
          defaultChecked
          type='checkbox'
          onChange={(e) => setHasBanner(e.target.checked)}
          required={false}
        />
      </div>

      <div className='buttons'>
        <button
          disabled={buttonDisabled}
          className='button'
          onClick={() => {
            onGenerateClick({
              name,
              occupation,
              phone,
              email,
              hasBanner,
              alternatePhone,
            });
          }}
        >
          Generate
        </button>
        <label className='or'>or</label>
        <button
          disabled={buttonDisabled}
          className='button'
          onClick={() => {
            onCopyToClipboardClick({
              name,
              occupation,
              phone,
              email,
              hasBanner,
              alternatePhone,
            });
          }}
        >
          Copy to clipboard
        </button>
      </div>
    </div>
  );
};

export default Form;

type FormProps = {
  onGenerateClick: (data: FormData) => void;
  onCopyToClipboardClick: (data: FormData) => void;
};

export type FormData = {
  name: string;
  occupation: string;
  phone: string;
  alternatePhone: string;
  email: string;
  hasBanner: boolean;
};
