import React from 'react';
import '../styles/components/Generated.scss';
import { FormData } from './Form';

const Generated = ({
  name,
  occupation,
  phone,
  email,
  hasBanner,
  alternatePhone,
}: GeneratedProps) => {
  const host = window.location.host;
  return (
    <table
      dangerouslySetInnerHTML={{
        __html: `<!doctype html>
        <html
          xmlns="http://www.w3.org/1999/xhtml"
          xmlns:v="urn:schemas-microsoft-com:vml"
          xmlns:o="urn:schemas-microsoft-com:office:office"
        >
          <head>
            <title></title>
            <!--[if !mso]><!-->
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <!--<![endif]-->
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <style type="text/css">
              #outlook a {
                padding: 0;
              }
              body {
                margin: 0;
                padding: 0;
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
              }
              table,
              td {
                border-collapse: collapse;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
              }
              img {
                border: 0;
                height: auto;
                line-height: 100%;
                outline: none;
                text-decoration: none;
                -ms-interpolation-mode: bicubic;
              }
              p {
                display: block;
                margin: 13px 0;
              }
            </style>
            <!--[if mso]>
              <noscript>
                <xml>
                  <o:OfficeDocumentSettings>
                    <o:AllowPNG />
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                  </o:OfficeDocumentSettings>
                </xml>
              </noscript>
            <![endif]-->
            <!--[if lte mso 11]>
              <style type="text/css">
                .mj-outlook-group-fix {
                  width: 100% !important;
                }
              </style>
            <![endif]-->
            <!--[if !mso]><!-->
            <link
              href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700"
              rel="stylesheet"
              type="text/css"
            />
            <style type="text/css">
              @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
            </style>
            <!--<![endif]-->
            <style type="text/css">
              @media only screen and (min-width: 480px) {
                .mj-column-per-100 {
                  width: 100% !important;
                  max-width: 100%;
                }
              }
            </style>
            <style media="screen and (min-width:480px)">
              .moz-text-html .mj-column-per-100 {
                width: 100% !important;
                max-width: 100%;
              }
            </style>
            <style type="text/css">
              @media only screen and (max-width: 480px) {
                table.mj-full-width-mobile {
                  width: 100% !important;
                }
                td.mj-full-width-mobile {
                  width: auto !important;
                }
              }
            </style>
            <style type="text/css"></style>
          </head>
          <body style="word-spacing: normal">
            <div>
              <!--[if mso | IE]><table align="left" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:260px;" width="260" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
              <div
                style="
                  background: #ffffff;
                  background-color: #ffffff;
                  margin: 0px auto;
                  max-width: 260px;
                "
              >
                <table
                  align="left"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="background: #ffffff; background-color: #ffffff; width: 100%"
                >
                  <tbody>
                    <tr>
                      <td
                        style="
                          direction: ltr;
                          font-size: 0px;
                          padding: 0px;
                          text-align: left;
                        "
                      >
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:200px;" ><![endif]-->
                        <div
                          class="mj-column-per-100 mj-outlook-group-fix"
                          style="
                            font-size: 0px;
                            text-align: left;
                            direction: ltr;
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;
                          "
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            style="vertical-align: top"
                            width="100%"
                          >
                            <tbody>
                             
                            
                              <tr>
                                <td
                                  align="left"
                                  style="
                                    font-size: 0px;
                                    padding: 10px 0px;
                                    padding-top: 30px;
                                    padding-bottom: 0px;
                                    word-break: break-word;
                                  "
                                >
                                  <div
                                    style="
                                      font-family: arial;
                                      font-size: 16px;
                                      font-weight: 700;
                                      line-height: 1;
                                      text-align: left;
                                      color: #000;
                                    "
                                  >
                                    ${name}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  style="
                                    font-size: 0px;
                                    padding: 10px 0px;
                                    padding-top: 5px;
                                    padding-bottom: 11px;
                                    word-break: break-word;
                                  "
                                >
                                  <div
                                    style="
                                      font-family: arial;
                                      font-size: 10px;
                                      line-height: 1;
                                      text-align: left;
                                      color: #0076F1;
                                      text-transform: uppercase; 
                                      letter-spacing: 1.6px; 
                                    "
                                  >
                                    ${occupation}
                                  </div>
                                </td>
                              </tr>
                           
                            
                              <tr>
                                <td
                                  align="left"
                                  style="
                                    font-size: 0px;
                                    padding: 10px 0px;
                                    padding-top: 0px;
                                    padding-bottom: ${
                                      alternatePhone ? '5px' : '16px'
                                    };
                                    word-break: break-word;
                                  "
                                >
                                  <div
                                    style="
                                      font-family: arial;
                                      font-size:13px;
                                      line-height: 1;
                                      text-align: left;
                                      color: #000;
                                      white-space: nowrap;
                                    "
                                  >
                                    <a
                                      href="tel:${phone.split(' ').join('')}"
                                      target="_blank"
                                      style="
                                        text-decoration: none;
                                        color: #000 !important;
                                      "
                                      >${phone} </a
                                    >   <span style="color:#CFCFCF;padding:0 5px">|</span>   <a
                                    href="mailto:${email}"
                                    target="_blank"
                                    style="
                                      text-decoration: none !important;
                                      color: #000 !important;
                                    "
                                    > ${email}</a
                                  >
                                  </div>
                                </td>
                               </tr>

                              ${
                                alternatePhone
                                  ? `<tr>
                               <td
                                 align="left"
                                 style="
                                   font-size: 0px;
                                   padding: 10px 0px;
                                   padding-top: 0px;
                                   padding-bottom: 16px;
                                   word-break: break-word;
                                 "
                               >
                                 <div
                                   style="
                                     font-family: arial;
                                     font-size:13px;
                                     line-height: 1;
                                     text-align: left;
                                     color: #000;
                                     white-space: nowrap;
                                   "
                                 >
                                   <a
                                     href="tel:${alternatePhone
                                       .split(' ')
                                       .join('')}"
                                     target="_blank"
                                     style="
                                       text-decoration: none;
                                       color: #000 !important;
                                     "
                                     >${alternatePhone} </a
                                   >  
                                 </div>
                               </td>
                              </tr>`
                                  : ''
                              }
                        
                              <tr>
                                <td
                                  align="left"
                                  style="
                                    font-size: 0px;
                                    padding: 10px 0px;
                                    padding-top: 4px;
                                    padding-bottom: 11px;
                                    word-break: break-word;
                                  "
                                >
                                  <div
                                    style="
                                      font-family: arial;
                                      font-size: 13px;
                                      line-height: 110%; 
                                      text-align: left;
                                      color: #000;
                                      white-space: nowrap;
                                    "
                                  >
                                  Altron Modular<br/>
                                  Novodvorská 994/138, 142 21  Prague 4<br/>
                                  Czech Republic
                                  </div>
                                </td>
                              </tr>
                            
                              <tr>
                                <td
                                  align="left"
                                  style="
                                    font-size: 0px;
                                    padding: 10px 0px;
                                    padding-top: 4px;
                                    padding-bottom: 0px;
                                    word-break: break-word;
                                  "
                                >
                                  <div
                                    style="
                                      font-family: arial;
                                      font-size: 12px;
                                      line-height: 1;
                                      text-align: left;
                                      color: #0076F1;
                                    "
                                  >
                                    <a
                                      href="https://modular.altron.net"
                                      target="_blank"
                                      style="
                                        text-decoration: none !important;
                                        text-decoration: none;
                                        color: #0076F1 !important;
                                      "
                                      ><span
                                        style="
                                          text-decoration: none;
                                          font-weight: normal;
                                          color: #0076F1 !important;
                                        "
                                        >modular.altron.net</span
                                      ></a
                                    >
                                  </div>
                                </td>
                              </tr>
                              ${
                                hasBanner
                                  ? ` <tr>
                              <td
                                align="left"
                                style="
                                  font-size: 0px;
                                  padding: 20px 0px;
                                  padding-bottom: 0px;
                                  word-break: break-word;
                                "
                              >
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  role="presentation"
                                  style="
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                  "
                                >
                                  <tbody>
                                    <tr>
                                      <td style="width: 300px"> <a
                                      href="https://modular.altron.net"
                                      target="_blank"
                          
                                      "
                                      >
                                        <img
                                          alt="Altron Modular banner"
                                          height="auto"
                                          src="https://${host}/img/banner.png"
                                          style="
                                            border: 0;
                                            display: block;
                                            outline: none;
                                            text-decoration: none;
                                            height: auto;
                                            width: 100%;
                                            font-size: 13px;
                                          "
                                          width="150"
                                        /></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>`
                                  : ''
                              }
                              <tr>
                                <td
                                  align="left"
                                  style="
                                    font-size: 0px;
                                    padding: 10px 0px;
                                    padding-top: 30px;
                                    word-break: break-word;
                                  "
                                >
                                  <table
                                    cellpadding="0px"
                                    cellspacing="0"
                                    width="130"
                                    border="0"
                                    style="
                                      color: #000000;
                                      font-family: Ubuntu, Helvetica, Arial, sans-serif;
                                      font-size: 13px;
                                      line-height: 22px;
                                      table-layout: auto;
                                      width: 130px;
                                      border: none;
                                    "
                                  >
                                    <tr></tr>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!--[if mso | IE]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--[if mso | IE]></td></tr></table><![endif]-->
            </div>
          </body>
        </html>
        `,
      }}
    />
  );
};

export default Generated;

type GeneratedProps = FormData & {};
